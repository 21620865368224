<template>
  <div>
    <TransitionGroup name="slide-in" mode="out-in">
      <SignIn @submit="handleLogin" />
    </TransitionGroup>
  </div>
</template>

<script setup lang="ts">
const router = useRouter();

function handleLogin(email: string) {
  router.push({ path: '/login/welcome', query: { email } });
}

definePageMeta({
  pageTransition: {
    mode: 'out-in',
    name: 'slide-in',
  },
});
</script>
